import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import SEO from '../components/seo'
import Form from '../components/Form'
import '../components/profile-test.css'
import ScheduleSection from '../components/ScheduleSection'

class ProfileTestPage extends React.Component {
  render() {
    const siteTitle = this.props.data.site.siteMetadata.title
    const siteDescription = this.props.data.site.siteMetadata.description

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO
          title="Profile Test - Single Men & Women"
          description={siteDescription}
        />
        <div className="profile-test-page page-heading text-center">
          <div className="container">
            <h1 className="text-white m-0">Profile Test</h1>
            <hr />
            <h4 className="text-white m-0">
              High End Matchmaking Services in Colorado Springs
            </h4>
          </div>
        </div>
        <div className="page-content">
          <div className="container">
            <div className="row justify-content-around">
              <div className="col-md-8">
                <h2 className="text-center mt-0">
                  Find love! Start by taking my free profile test!
                </h2>
                <hr />
                <p className="subheading font-italic text-center pb-4">
                  My profile test is designed to help me learn more about you.
                  This is instrumental in my matchmaking process. Please
                  complete the answers below and I will personally call you to
                  go over your profile test and the next steps.
                </p>
                <p className="small text-center py-4">
                  These statements are designed to measure the way you feel
                  about certain areas. There are no right or wrong answers. Use
                  your first impressions, answer honestly, and try to avoid the
                  neutral answer (#3), unless it absolutely applies. Work
                  quickly, checking the appropriate box beside each statement.
                </p>
                <p className="font-weight-bold text-center">
                  1=Clearly Agree &bull; 2=Slightly Agree &bull; 3=Neutral
                  &bull; 4=Slightly Disagree &bull; 5=Clearly Disagree
                </p>
                <div>
                  <Form
                    form_id="008e7cef-fdcb-4ebd-82a4-4bdb6f7fd485"
                    form_name="Profile Test Form"
                    form_classname="contact-form"
                    scriptSrc="https://staging-forms.webriq.com/js/initReactForms"
                  >
                    <div className="profile-info">
                      <h3 className="text-center">Contact Information</h3>
                      <div className="form-row justify-content-around">
                        <div className="col-md-5 mt-2">
                          <div className="form-group">
                            <input
                              className="form-control"
                              type="text"
                              name="Name"
                              id="your_name"
                              required={true}
                            />
                            <label htmlFor="your_name">Your name*</label>
                          </div>
                        </div>
                        <div className="col-md-5 mt-2">
                          <div className="form-group">
                            <input
                              className="form-control"
                              type="email"
                              name="Email"
                              id="your_email"
                              required={true}
                            />
                            <label htmlFor="your_email">Your email*</label>
                          </div>
                        </div>
                        <div className="col-md-5 mt-2">
                          <div className="form-group">
                            <input
                              className="form-control"
                              type="phone"
                              name="Home or Cell No."
                              id="contact_number"
                              required={true}
                            />
                            <label htmlFor="contact_number">
                              Your home or cell #*
                            </label>
                          </div>
                        </div>
                        <div className="col-md-5 mt-2">
                          <div className="form-group">
                            <input
                              className="form-control"
                              type="phone"
                              name="Work No."
                              id="work_number"
                            />
                            <label htmlFor="work_number">Your work #</label>
                          </div>
                        </div>
                        <div className="col-md-5 mt-2">
                          <div className="form-group">
                            <input
                              className="form-control"
                              type="text"
                              name="City"
                              id="your_city"
                              required={true}
                            />
                            <label htmlFor="your_city">Your city*</label>
                          </div>
                        </div>
                        <div className="col-md-5 mt-2">
                          <div className="form-group">
                            <input
                              className="form-control"
                              type="text"
                              name="State"
                              id="state"
                              required={true}
                            />
                            <label htmlFor="state">Your state*</label>
                          </div>
                        </div>
                        <div className="col-md-5 mt-2">
                          <div className="form-group">
                            <input
                              className="form-control"
                              type="text"
                              name="ZIP"
                              id="zip"
                              required={true}
                            />
                            <label htmlFor="zip">Your ZIP code*</label>
                          </div>
                        </div>
                        <div className="col-md-5 mt-2">
                          <div className="form-group">
                            <input
                              className="form-control"
                              type="text"
                              name="Occupation"
                              id="occupation"
                            />
                            <label htmlFor="occupation">Your occupation</label>
                          </div>
                        </div>
                        <div className="col-md-5 mt-2">
                          <div className="form-group contact-dropdown">
                            <label
                              htmlFor="i_am_currently"
                              className="position-relative p-0"
                            >
                              I am currently
                            </label>
                            <select
                              className="form-control"
                              name="I Am Currently"
                              id="i_am_currently"
                            >
                              <option value="Single">Single</option>
                              <option value="Divorced">Divorced</option>
                              <option value="Legally Separated">
                                Legally Separated
                              </option>
                              <option value="Widowed">Widowed</option>
                            </select>
                          </div>
                        </div>
                        <div className="col-md-5 mt-2">
                          <div className="form-group contact-dropdown">
                            <label
                              htmlFor="heard_about_pmd"
                              className="position-relative p-0"
                            >
                              I heard about perfectly matched through
                            </label>
                            <select
                              className="form-control"
                              name="Heard About PMD"
                              id="heard_about_pmd"
                            >
                              <option value="Friend">Friend</option>
                              <option value="Wall Ad">Wall Ad</option>
                              <option value="Newspaper">Newspaper</option>
                              <option value="Radio">Radio</option>
                              <option value="The MBP Video">
                                The MBP Video
                              </option>
                              <option value="Facebook Ads">Facebook Ads</option>
                              <option value="TV">TV</option>
                              <option value="Other">Other</option>
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="form-row justify-content-center">
                      <div className="col-md-4 text-center">
                        <div className="form-group bg-white">
                          <button
                            className="btn btn-primary btn-block"
                            type="submit"
                          >
                            Send
                          </button>
                        </div>
                      </div>
                    </div>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ScheduleSection />
      </Layout>
    )
  }
}
export default ProfileTestPage

export const profileTestPagequery = graphql`
  query profileTestPagequery {
    site {
      siteMetadata {
        title
        author
        description
      }
    }
  }
`
